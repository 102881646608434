.main {
  width: 100%;
  height: 40rem;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

.title {
  margin: auto;
  color: var(--color-2-0);
  font-family: 'Noto Serif Display', serif;
  font-size: 7rem;
  font-weight: 300; // 300
  letter-spacing: 10px;
  text-align: center;
}

.subtitle {
  margin-bottom: 0;
  color: var(--color-2-0);
  font-family: 'Noto Serif Display', serif;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 5px;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 5rem;
    white-space: break-spaces;
  }

  .subtitle {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .subtitle {
    font-size: 1.4rem;
    white-space: break-spaces;
  }
}
.container {
  width: var(--container-width);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0 1rem 0;
}

.headline {
  margin: 1.5rem 0 1rem 0;
}

.answer {
  padding: 1rem;
  text-align: center;
}

.title {
  margin: 4rem 0 0;
  font-family: "Noto Serif Display", serif;
  text-align: center;
}

.date {
  margin: 0 0 1rem;
  font-size: 1.6rem;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;

  > p {
    margin: 0.2rem;
    font-size: 1.2rem;
    letter-spacing: 2px;
  }
}

.location {
  max-width: 20rem;

}

.locationText {
  width: fit-content;
  margin: 0.2rem 0 0;
  text-align: center;
  line-height: 1.6rem;
  font-size: 1.4rem;

  > svg {
    top: 0.1rem;
    position: relative;
    padding: 0.5rem 0.5rem 0 0 ;
  }
}

.locationMap {
  @extend .locationText;

  &:hover {
    cursor: pointer;
    color: var(--color-6-900);
    font-weight: 600;
  }
}

.dressCode {
  width: 40%;
  margin: 4rem 0 4rem;
  padding: 1rem;
  border: 1px solid var(--color-6-800);
  border-radius: var(--border-radius-large);
  text-align: center;
  box-shadow: var(--shadow);

  > p {
    font-size: 1.2rem;
  }
}

.dressCodeTitle {
  font-size: 1.4rem;
  font-weight: bold;
}


@media screen and (max-width: 600px) {
  .dressCode {
    width: 65%;
  }
}

@media screen and (max-width: 1200px) {
  .dressCode {
    width: 50%;
  }
}
.main {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 4rem 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: var(--color-2-0);
  z-index: 1000;
}

.links {
  font-family: "Noto Serif", serif;
  font-weight: 300;
  height: fit-content;
  padding: 1rem 0 0.5rem;
  letter-spacing: 2px;
  font-size: 0.9rem;
  text-decoration: none;
  color: var(--color-6-800);
  background-color: transparent;
  cursor: pointer;
  transition: font-weight 0.2s;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  &:hover {
    border-bottom: 1px solid var(--color-6-800);
    cursor: pointer;
  }
}

/* Burger Menu Styles */
.burgerMenu {
  display: none;
  cursor: pointer;
}

/* Mobile Menu Styles */
.mobileMenu {
  min-width: 10rem;
  padding: 1rem;
  position: absolute;
  top: 55px;
  left: 25px;
  display: none;
  flex-direction: column;
  background-color: var(--color-2-0);
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow);
}

.mobileLinks {
  text-decoration: none;
  color: var(--color-6-800);
  padding: 0.5rem;
  border-radius: var(--border-radius-large);

  &:hover {
    color: var(--color-2-0);
    background-color: var(--color-6-900);
  }
}

/* Media Query for smaller screens */
@media screen and (max-width: 1200px) {
  .main {
    background-color: unset;
    padding: 2rem;
  }

  .linkContainer {
    display: none;
  }

  .burgerMenu {
    display: block; /* Show burger menu icon for smaller screens */
  }

  .mobileMenu {
    display: flex; /* Show mobile menu for smaller screens */
  }
}

.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
}

.vertical {
    @extend .container;
    flex-direction: column;
    justify-content: flex-start;
    align-items: unset;
}

.asterisk {
    line-height: 0.5rem;
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-5-800);
}

.options {
    display: flex;
    border: 1px solid var(--color-6-800);
    border-radius: var(--border-radius-large);
}

.divider {
    height: 100%;
    width: 1px;
    background-color: var(--color-6-800);
}

.on {
    width: 100%;
    padding: 0.5rem;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    text-align: center;
}

.off {
    width: 100%;
    padding: 0.5rem;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    text-align: center;
}

.selected {
    background-color: var(--color-6-800);
    color: var(--color-2-0);
}
.title {
  margin: 4rem 0 0;
  font-family: "Noto Serif Display", serif;
}

.date {
  margin: 0 0 2rem;
  font-size: 1.6rem;
}

.timeline {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.event {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-right: 3rem;
}

.event.right {
  justify-content: flex-end;
  margin-right: 0;
  margin-left: 3rem;
}

.event .content {
  border-radius: 6px;
  position: relative;
  width: 50%;
  text-align: center;
}
//
//.event.left::before {
//  left: -25px;
//}
//
//.event.right::before {
//  right: -25px;
//}

.timelineContainer {
  position: relative;
  width: 80%;
  display: flex;
  justify-content: center;
}

.timelineSvg {
  position: absolute;
  top: 2rem;
  left: 52%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.timelinePath {
  stroke: var(--color-6-800);
  stroke-width: 1;
  fill: none;
}

.dressCode {
  width: 40%;
  margin: 1rem 0 3rem;
  padding: 1.5rem;
  border: 1px solid var(--color-6-800);
  border-radius: var(--border-radius-large);
  text-align: center;
  box-shadow: var(--shadow);

  > p {
    font-size: 1.2rem;
  }
}

.dressCodeTitle {
  font-size: 1.4rem;
  font-weight: bold;
}

.link {
  > a {
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  //.event::after {
  //  left: 50%;
  //  margin-left: -1px;
  //}

  .event.right,
  .event.left {
    justify-content: center;
    margin: 1rem 0
  }

  .event.left .content,
  .event.right .content {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .dressCode {
    width: 65%;
  }
}

@media screen and (max-width: 1200px) {
  .dressCode {
    width: 50%;
  }
}

@media screen and (max-width: 1194px) {
  .timelineContainer {
    margin-bottom: 10rem;
  }
}

.container {
  width: var(--container-width);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.title {
  font-size: 1.5rem;
}

.section {
  font-size: 2rem;
  letter-spacing: 5px;
}

.text {
  margin-bottom: 2rem;
}

.text_extra {
  margin-bottom: 0;
}

.bulletPoint {
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.extra {
  margin-bottom: 4rem;
}

.extraContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

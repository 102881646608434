@import "stylesheets/animations";

.container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  animation: $fadeIn;
}

.main {
  width: 100%;
  min-height: 100vh;
  flex: 1;
  background-color: var(--color-2-0);
}

@media screen and (min-width: 768px) {
  .main {
    height: 100vh;
    min-height: unset;
  }
}

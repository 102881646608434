.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2.4rem 2rem;
    box-shadow: var(--shadow);
    border: 1px solid var(--color-6-800);
}

@media screen and (max-width: 995px) {
    .container {
        box-shadow: none;
        border: none;
    }
}
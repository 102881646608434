.section {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.6rem;

  > span {
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.title {
  color: var(--black) !important;
  max-width: 18rem;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;

  > p {
    margin: 0.2rem;
    font-size: 1.2rem;
    letter-spacing: 2px;
  }
}

.location {
  max-width: 18rem;

}

.locationText {
  width: fit-content;
  margin: 0.2rem 0 0;
  text-align: center;
  line-height: 1.6rem;

  > svg {
    top: 0.1rem;
    position: relative;
    padding: 0.5rem 0.5rem 0 0 ;
  }
}

.locationMap {
  @extend .locationText;

  &:hover {
    cursor: pointer;
    color: var(--color-6-900);
  }
}

@media screen and (max-width: 1200px) {
  .section {
    width: 100%;
  }

  .title {
    font-size: 1rem;
  }
}

.container {
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-6-800);
  border-radius: var(--border-radius-large);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: var(--color-6-900);
  }
}

.buttonText {
  margin: 0;
  color: var(--color-2-0);
  font-size: 1.1rem;
  text-decoration: none;
}

.divider {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.line {
    width: 10rem;
    height: 1px;
    background-color: var(--color-6-800);
}


@media screen and (max-width: 768px) {
    .image {
        width: 6rem;
    }
}

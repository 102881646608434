.container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 4rem 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.title {
  margin: 4rem 0 1rem;
  letter-spacing: 0.5rem;
  text-align: center;
}

.subtitle {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.caption {
  width: 75%;
  margin-top: 2rem;
  text-align: center;
  line-height: 2rem;
}

.form {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.sectionTitle {
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 0 1rem;
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.confirmation {
  width: 100%;
  padding: 0.5rem 0;
  margin: 1rem 0 4rem;
  border: 1px solid var(--color-6-800);
  border-radius: var(--border-radius-large);
  background-color: var(--color-6-800);
  color: var(--color-2-0);
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: var(--color-6-900);
    color: var(--color-2-0);
    border: 1px solid var(--color-6-900);
  }
}

.confirmationDisabled {
  @extend .confirmation;
  background-color: gray;
  cursor: not-allowed;

  &:hover {
    background-color: gray;
  }
}

.thankYou {
  font-size: 4rem;
  margin: 7rem 0;
}

@media screen and (max-width: 350px) {
  .container {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 600px) {
  .title {
    white-space: break-spaces;
  }
}
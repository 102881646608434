.languages {
    font-family: "Noto Serif", serif;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0;
    letter-spacing: 2px;
    font-size: 0.9rem;
    color: var(--color-6-800);
    background-color: transparent;
}

.text {
    @extend .languages;

    &:hover {
        font-weight: bold;
        cursor: pointer;
    }
}
@media screen and (max-width: 1200px) {
    .languages {
        color: var(--color-2-0);
    }
}

@media screen and (max-width: 992px) {
    .languages {
        font-size: 1.2rem;
    }
}

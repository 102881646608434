.container {
    padding: 4rem 1rem 1rem;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-6-800);
}

.sentence {
    width: 60%;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    color: var(--color-2-0);
    white-space: break-spaces;
}

.email {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-2-0);
}

.rights {
    width: 90%;
    border-top: 0.8px solid var(--color-2-0);
    text-align: center;
    margin-top: 4rem;
    padding-top: 1rem;

    > p {
        color: var(--color-2-0);
    }
}

@media screen and (max-width: 900px) {
    .sentence {
        width: 90%;
    }
}

.overlay {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.container {
  width: 40rem;
  height: 25rem;
  background: var(--color-2-0);
  padding: 2rem;
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow);
  text-align: center;
}

.loginContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  width: 80%;
  font-family: "Antic Didone", serif;
  font-size: 2.4rem;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 3px;
}

.titleAnd {
  @extend .title;
  font-size: 1.8rem;
}

.subtitle {
  width: 80%;
  padding-bottom: 2rem;
  margin: 0;
  color: var(--color-6-800);
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 2px;
}

.form {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: var(--gap-small) 0;
}

.button {
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: unset;
  cursor: pointer;
}

.languages {
  display: flex;
  flex-direction: row-reverse;
}


@media screen and (max-width: 768px) {
  .container {
    width: 30rem;
  }

  .form {
    width: 80%;
    gap: var(--gap-small);
  }
}
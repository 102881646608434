:root {
  --color-2-0: white;
  --color-2-1: #f1f1f1;
  --color-1-800: #27241d; // Dark text color
  --color-6-600: #dfe4df; // Background color
  --color-6-800: #879388; // Text & background button color
  --color-6-900: #717a72; // Hover color
  --color-5-800: #ec625b;

  --border-radius: 15px;
  --border-radius-medium: 10px;
  --border-radius-large: 4px;

  --letter-space-headings: 5px;

  --container-width: 70%;
  --gap-small: 1rem;

  --shadow-light: 0 2px 6px 0 rgb(0 0 0 / 7%);
  --shadow: 0 8px 16px rgb(2 8 20 / 8%), 0 4px 8px rgb(2 8 20 / 8%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: inherit;
  letter-spacing: 1px;
  font-family: 'Noto Serif', serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 1rem;
  color: var(--color-6-800);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 50%;
}

h3 {
  font-size: 3rem;
  font-weight: 400;
}

h6 {
  margin: 0.4rem 0;
  font-size: 1rem;
  font-weight: 400;
}

p {
  font-family: 'Noto Serif', serif;
  font-weight: 200;
}

[hidden] {
  display: none !important;
}

@media screen and (min-width: 350px) {
  html {
    font-size: 80%;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --gap-small: 2rem;
  }
}

@media screen and (min-width: 992px) {
  :root {
    --gap: 2.5rem;
  }

  html {
    font-size: 100%;
  }
}

.title {
  margin: 3rem 0;
}

.container {
  max-width: 70rem;
  overflow: scroll;
}

.table {
  border-collapse: collapse;

  th {
    font-family: 'Noto Serif', serif;
    font-optical-sizing: auto;
    font-weight: 200;
    border-top: 2px solid var(--color-6-800);
    border-bottom: 2px solid var(--color-6-800);
    padding: 1.2rem;
  }

  td {
    max-width: 15rem;
    padding: 1rem 0;
    text-align: center;
    border-bottom: 0.5px solid var(--color-6-800);
    overflow: scroll;
  }
}

.pages {
  width: 70rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 4rem;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0;
}

.activePage {
  padding: 0.2rem 1rem;
  background-color: var(--color-2-0);
  border: 1px solid var(--color-2-0);
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.inactivePage {
  padding: 0.2rem 0;
  background-color: var(--color-2-0);
  border: 1px solid var(--color-2-0);

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}

.downloadDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.downloadButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: var(--color-2-0);
  border: 1px solid #9a9895;
  border-radius: var(--border-radius-large);
  font-size: 1rem;

  &:hover {
    cursor: pointer;
    background-color: #9a9895;
    color: var(--color-2-0);

    > svg {
      stroke: var(--color-2-0);
    }
  }
}

@media screen and (max-width: 768px) {
  .container,
  .pages {
    width: 80%;
  }
}

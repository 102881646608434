.headline {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  letter-spacing: 4px;
}

// Main heading
.level1 {
  @extend .headline;
  margin-top: 2.5rem;

  .label {
    font-family: 'Kapakana', serif;
    letter-spacing: var(--letter-space-headings);
    padding-bottom: 0.2rem;
    font-size: 5rem;
    font-weight: 400;
    color: var(--color-6-900);
  }
}

// Heading subtitle
.level2 {
  @extend .headline;
  margin-top: 0.5rem;

  .label {
    font-family: 'Noto Serif', serif;
    letter-spacing: var(--letter-space-headings);
    font-size: 2rem;
    font-weight: 400;
    color: var(--color-6-900);
  }
}

.level3 {
  @extend .headline;
  .label {
    font-family: 'Noto Serif', serif;
    letter-spacing: var(--letter-space-headings);
    font-size: 1.6rem;
    font-weight: 300;
    color: var(--color-6-900);
  }
}


@media screen and (max-width: 1200px) {
  .level2 {
    .label {
      font-size: 1.6rem;
    }
  }
}
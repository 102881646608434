// Animation variables
$fadeIn: fadeInAnimation 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
$fadeInForward: fadeInForwardAnimation 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
$fadeInBottom: fadeInBottomAnimation 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
$scaleInTop: scaleInTopAnimation 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
$scaleInBottom: scaleInBottomAnimation 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
$shimmer: shimmerAnimation 1.7s linear infinite forwards;

// Keyframes
@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInForwardAnimation {
    from {
        transform: translateZ(-8rem);
        opacity: 0;
    }
    to {
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes fadeInBottomAnimation {
    from {
        transform: translateY(0.8rem);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes scaleInTopAnimation {
    from {
        transform: scale(0);
        transform-origin: 50% 0;
    }
    to {
        transform: scale(1);
        transform-origin: 50% 0;
    }
}

@keyframes scaleInBottomAnimation {
    from {
        transform: scale(0);
        transform-origin: 50% bottom;
    }
    to {
        transform: scale(1);
        transform-origin: 50% bottom;
    }
}

@keyframes shimmerAnimation {
    from {
        background-position: -1000px 0;
    }
    to {
        background-position: 1000px 0;
    }
}
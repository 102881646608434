.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60rem;
  position: relative;
  height: fit-content;
  padding: 2rem 0;
}

.index {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 2rem;

  margin-bottom: 3rem;
  text-align: center;

  > div > a {
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.indexElements {
  display: flex;
}

.separator {
  margin: 0 1rem;
}

.section {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .index {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .indexElements {
    display: flex;
  }

  .separator {
    margin: 0 0.5rem;
  }
}
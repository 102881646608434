.container {
  line-height: 2rem;
}

.description {
  font-size: 1.1rem;
  font-weight: 500;
  white-space: break-spaces;
}

// paragraph
.level1 {
  @extend .description;
}

.level2 {
  @extend .description;
  line-height: 2.5rem;
}
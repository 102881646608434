.container {
  min-height: 60rem;
  position: relative;
  top: 20rem;
  height: fit-content;
  padding: 2rem 0;
}

.mainTitle {
  text-align: center;
}

.text {
  width: 75%;
  padding-bottom: 3rem;
  text-align: center;
}

.columns {
  display: flex;
  width: 100%;
  gap: var(--gap-small);
  margin-bottom: 5rem;
}

.columnRight {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-small);
}

.columnLeft {
  @extend .columnRight;
  align-items: flex-start;
}

.image {
  width: 100%;
  height: 35rem;
  background-size: cover;
  background-position: center;
}

.imageRight {
  @extend .image;
  height: 35rem;
}

.sectionRight {
  width: 80%;
  padding: 2rem 0 2rem 3rem;
  text-align: right;

  .link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: var(--color-6-900);
      > svg {
        stroke: var(--color-6-900);
      }
    }
  }
}

.sectionLeft {
  @extend .sectionRight;
  padding: 0 3rem 2rem 0;
  text-align: left;

  .link {
    justify-content: flex-start;
  }
}

.titleLeft {
  display: flex;
  justify-content: left;
}

.titleRight {
  display: flex;
  justify-content: right;
}

.confirmation {
  width: 50%;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  line-height: 2.5rem;
}

@media screen and (max-width: 1200px) {
  .columns {
    width: 90%;
  }

  .title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .text {
    padding-bottom: 2rem;
  }

  .columns {
    width: 80%;
    flex-direction: column;
  }

  .image {
    display: none;
  }

  .sectionRight,
  .sectionLeft {
    width: 100%;
    text-align: left;
    padding: 0;

    .link {
      justify-content: flex-start;
    };
  }

  .bank {
    text-align: center;
  }

  .titleRight,
  .titleLeft {
    justify-content: center;
    text-align: center;
  }
}

.container {
  position: relative;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 9rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 0;
  }
}

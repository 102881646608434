.container {
    width: 100%;
}

.wrapper {
    width: 100%;
    border: 1px solid var(--color-6-800);
    border-radius: var(--border-radius-large);
}

.label {
    position: relative;
    display: block;
    padding: 0 0 1rem;
    font-size: 1rem;
    font-weight: 400;

    > span {
        display: inline-block;
        border-radius: var(--border-radius-large);
        background-color: var(--color-2-0);
        z-index: 2;
    }
}

.asterisk {
    line-height: 0.5rem;
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-5-800);
}

.icon {
    position: absolute;
    left: 1rem;
    width: 2.2rem;
    height: 2.2rem;
    transition: color 0.2s;
    font-size: 2.2rem;
    color: var(--color-1-800);
    cursor: text;
}

.actionIcon {
    @extend .icon;
    right: 0.4rem;
    left: auto;

    &:hover {
        color: var(--color-3-800);
        cursor: pointer;
    }
}

.input {
    width: 100%;
    height: 2rem;
    padding: 0.2rem 0.8rem;
    border: none;
    border-radius: var(--border-radius-large);
    background-color: transparent;
    transition: border 0.2s;
    font-size: 1rem;
    outline: none;

    &:focus {
        + .icon {
            color: var(--color-3-800);
        }
    }

    &:disabled {
        background-color: var(--color-2-100);
        color: var(--color-1-800);
        cursor: not-allowed;
    }

    &::placeholder {
        font-size: 1rem;
        color: var(--color-6-800);
    }

    &:-webkit-autofill {
        box-shadow: 0 0 0 1000px var(--color-3-200) inset;
        -webkit-text-fill-color: var(--color-3-800);
        background-color: transparent;

        &::selection {
            -webkit-text-fill-color: var(--color-2-0);
        }
    }
}

.textArea {
    height: 100%;
    min-height: 10rem;
    line-height: 1.4;
    margin-bottom: -0.2rem;
    padding: 0.5rem;
    resize: none;
}